/* Vaishnav Kumar Koratpally - 1002030414
Naresh Kandhyanam - 1002039083
Abhinay Lakkamaneni - 1002028622 */
.About-us {
  width: 100%;
  text-align: center;
  /* background-color: #ccc; */
  /* margin-top: 30px; */
  margin-bottom: 30px;
}
.aboutHeading {
  float: left;
  margin: 30px 30px;
}
h1 {
  color: rgb(233, 128, 128);
  font-size: 35px;
}
img {
  border-radius: 50%;
}

p {
  font-size: 20px;
}
h3 {
  text-shadow: 2px 2px 5px red;
}
