/* Vaishnav Kumar Koratpally - 1002030414
Naresh Kandhyanam - 1002039083
Abhinay Lakkamaneni - 1002028622 */
.car {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CartContainer {
  width: 70%;
  height: 90%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 20px #1687d933;
}

.Header {
  margin: auto;
  width: 90%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Heading {
  font-size: 20px;
  /* font-family: "Open Sans"; */
  font-weight: 700;
  color: #2f3841;
}

.Action {
  font-size: 14px;
  /* font-family: "Open Sans"; */
  font-weight: 600;
  color: #e44c4c;
  cursor: pointer;
  border-bottom: 1px solid #e44c4c;
}

.Cart-Items {
  margin: auto;
  width: 90%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-box {
  width: 15%;
  text-align: center;
}
.about {
  height: 100%;
  width: 24%;
}
.title {
  padding-top: 10px;
  line-height: 10px;
  font-size: 32px;
  font-family: "Open Sans";
  font-weight: 800;
  color: #202020;
}
.subtitle {
  /* line-height: 10px; */
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #909090;
  padding: 10px;
}

.counter {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: "Open Sans";
  font-weight: 900;
  color: #202020;
  cursor: pointer;
}
.count {
  font-size: 20px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #202020;
}

.prices {
  height: 100%;
  text-align: right;
}
.amount {
  padding-top: 20px;
  font-size: 26px;
  font-family: "Open Sans";
  font-weight: 800;
  color: #202020;
}
.save {
  padding-top: 5px;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #1687d9;
  cursor: pointer;
}
.remove {
  padding-top: 5px;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #e44c4c;
  cursor: pointer;
}

.pad {
  margin-top: 5px;
}

hr {
  width: 66%;
  float: right;
  margin-right: 5%;
}
.checkout {
  float: right;
  margin-right: 5%;
  width: 28%;
}
.total {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Subtotal {
  font-size: 22px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #202020;
}
.items {
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 500;
  color: #909090;
  line-height: 10px;
}
.total-amount {
  font-size: 36px;
  font-family: "Open Sans";
  font-weight: 900;
  color: #202020;
}
.button {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: none;
  background: linear-gradient(to bottom right, #b8d7ff, #8eb7eb);
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #202020;
}
