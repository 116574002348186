/* Vaishnav Kumar Koratpally - 1002030414
Naresh Kandhyanam - 1002039083
Abhinay Lakkamaneni - 1002028622 */
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Josefin Sans", sans-serif;
} */

body {
  background-color: #f3f3f3;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  display: flex;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
}

.wrapper .left {
  width: 35%;
  background: linear-gradient(to right, #01a9ac, #01dbdf);
  padding: 30px 25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
  color: #fff;
}

.wrapper .left img {
  border-radius: 5px;
  margin-bottom: 10px;
}

.wrapper .left h4 {
  margin-bottom: 10px;
}

.wrapper .left p {
  font-size: 12px;
}

.wrapper .right {
  width: 65%;
  background: #fff;
  padding: 30px 25px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.wrapper .right .info,
.wrapper .right .projects {
  margin-bottom: 25px;
}

.wrapper .right .info h3,
.wrapper .right .projects h3 {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: #353c4e;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.wrapper .right .info_data,
.wrapper .right .projects_data {
  display: flex;
  justify-content: space-between;
}

.wrapper .right .info_data .data,
.wrapper .right .projects_data .data {
  width: 45%;
}

.wrapper .right .info_data .data h4,
.wrapper .right .projects_data .data h4 {
  color: #353c4e;
  margin-bottom: 5px;
}

.wrapper .right .info_data .data p,
.wrapper .right .projects_data .data p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #919aa3;
}

.wrapper .social_media ul {
  display: flex;
}

.wrapper .social_media ul li {
  width: 45px;
  height: 45px;
  background: linear-gradient(to right, #01a9ac, #01dbdf);
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  line-height: 45px;
}

.wrapper .social_media ul li a {
  color: #fff;
  display: block;
  font-size: 18px;
}
