/* Vaishnav Kumar Koratpally - 1002030414
Naresh Kandhyanam - 1002039083
Abhinay Lakkamaneni - 1002028622 */
.hom {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  background-image: linear-gradient(
      to right,
      rgba(200, 8, 207, 0.75),
      rgba(28, 70, 184, 0.8)
    ),
    url(https://www.hmablogs.com/wp-content/uploads/2022/06/background-1-scaled.jpg);
  background-size: cover;
  background-attachment: fixed;
  color: white;
  font-family: "Fira Sans", sans-serif;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0% 5%;
}
.row .column1 {
  padding-right: 50px;
}
.column1 h1 {
  font-size: 60px;
  margin-bottom: 10px;
}
.column1 p {
  font-size: 25px;
  margin-bottom: 20px;
}
.column1 button {
  width: 150px;
  padding: 12px 20px;
  border-radius: 20px;
  border-style: none;
  color: blue;
  font-size: 17px;
  font-weight: 600;
}
/* Header content responsive */
@media screen and (max-width: 980px) {
  .column2 img {
    width: 350px;
  }
  .column1 h1 {
    font-size: 40px;
  }
  .column1 p {
    font-size: 17px;
  }
}

@media screen and (max-width: 600px) {
  .column1 h1 {
    font-size: 35px;
  }
  .row {
    flex-direction: column;
  }
  .row .column1 {
    padding: 30px;
  }
}
