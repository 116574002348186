/* Vaishnav Kumar Koratpally - 1002030414
Naresh Kandhyanam - 1002039083
Abhinay Lakkamaneni - 1002028622 */
.serv {
  margin: 0;
  padding: 0;
  font-family: "montserrat", sans-serif;
  color: #ddd;
  background: url(bg.jpg);
  height: 100vh;
  box-sizing: border-box;
}

.services-section {
  background-size: cover;
  padding: 60px 0;
}
.inner-width {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  overflow: hidden;
}
.section-title {
  text-align: center;
  color: #ddd;
  text-transform: uppercase;
  font-size: 30px;
}
.border {
  width: 160px;
  height: 2px;
  background: #82ccdd;
  margin: 40px auto;
}
.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
